import auth0 from 'auth0-js';
import { setCookie, getCookie, removeCookie } from 'lib/session';
import { GetServerSidePropsContext } from 'next';

declare var process: {
  browser: boolean;
};

const auth0Config = {
  domain: 'pawlytics.auth0.com',
  clientID: 'Uu26_hnPqSaCy6Fd-InrGwKmmyK7sHwd',
  scope: 'openid profile email',
  audience: 'https://api.pawlytics.com',
  responseType: 'token id_token',
  redirectUri: global.location?.origin,
};

export const webAuth = new auth0.WebAuth(auth0Config);

export const userCookieExist = (context?: GetServerSidePropsContext['req']) =>
  !!getCookie('jwt', context) && !!getCookie('idToken', context);

export const saveToken = (authResult: {
  accessToken: string;
  expiresIn: number;
  idToken: string;
}) => {
  if (!process.browser) {
    return;
  }
  setCookie('jwt', authResult.accessToken);
  setCookie('idToken', authResult.idToken);
};

export const deleteToken = () => {
  if (!process.browser) {
    return;
  }
  removeCookie('jwt');
  removeCookie('idToken');
  removeCookie('organization');
};

export const logout = () => {
  let cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf('=');
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};

export const getToken = (ctx?: GetServerSidePropsContext) =>
  getCookie('jwt', ctx?.req);
