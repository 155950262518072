import cookie from 'js-cookie';
import { GetServerSidePropsContext } from 'next';

declare var process: {
  browser: boolean;
};

const namespace = 'paw';

export const setCookie = (key: string, value: any, expires?: number) => {
  if (process.browser) {
    cookie.set(`${namespace}.${key}`, value, {
      expires: expires || 1,
      path: '/',
    });
  }
};

export const removeCookie = (key: string) => {
  if (process.browser) {
    cookie.remove(`${namespace}.${key}`, { expires: 1 });
  }
};

const getCookieFromBrowser = (key: string) => cookie.get(key);

const getCookieFromServer = (
  key: string,
  req: GetServerSidePropsContext['req']
): string | undefined => {
  if (!req.headers.cookie) {
    return undefined;
  }

  const rawCookie = req.headers.cookie
    .split(';')
    .find((c) => c.trim().startsWith(`${key}=`));

  if (!rawCookie) {
    return undefined;
  }

  return rawCookie.split('=')[1];
};

export const getCookie = (
  key: string,
  context?: GetServerSidePropsContext['req']
): string | undefined =>
  !process.browser && context
    ? getCookieFromServer(`${namespace}.${key}`, context)
    : getCookieFromBrowser(`${namespace}.${key}`);
